html {
    height: 100% !important;
}

body {
    height: 100% !important;
}

#root {
    height: 100% !important;
}
