/* Loading */

#loading {
    display: none;
    position: fixed;
    background-color: #EFF5F9DD;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    z-index: 4;
    overflow: hidden;
}

#loading span {
    margin: auto;
}

#loading.visible {
    display: flex;
}

.capa {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}